/*!
 * baguetteBox.js
 * @author  feimosi
 * @version 1.11.1
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  opacity: 0;
  z-index: 1000000;
  background-color: #000c;
  width: 100%;
  height: 100%;
  transition: opacity .5s;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

#baguetteBox-overlay.visible {
  opacity: 1;
}

#baguetteBox-overlay .full-image {
  text-align: center;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
}

#baguetteBox-overlay .full-image figure {
  height: 100%;
  margin: 0;
  display: inline;
}

#baguetteBox-overlay .full-image img {
  vertical-align: middle;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: inline-block;
  box-shadow: 0 0 8px #0009;
}

#baguetteBox-overlay .full-image figcaption {
  text-align: center;
  white-space: normal;
  color: #ccc;
  background-color: #0009;
  width: 100%;
  font-family: sans-serif;
  line-height: 1.8;
  display: block;
  position: absolute;
  bottom: 0;
}

#baguetteBox-overlay .full-image:before {
  content: "";
  width: 1px;
  height: 50%;
  margin-right: -1px;
  display: inline-block;
}

#baguetteBox-slider {
  white-space: nowrap;
  width: 100%;
  height: 100%;
  transition: left .4s, transform .4s;
  position: absolute;
  top: 0;
  left: 0;
}

#baguetteBox-slider.bounce-from-right {
  animation: .4s ease-out bounceFromRight;
}

#baguetteBox-slider.bounce-from-left {
  animation: .4s ease-out bounceFromLeft;
}

@keyframes bounceFromRight {
  0% {
    margin-left: 0;
  }

  50% {
    margin-left: -30px;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes bounceFromLeft {
  0% {
    margin-left: 0;
  }

  50% {
    margin-left: 30px;
  }

  100% {
    margin-left: 0;
  }
}

.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
  width: 44px;
  height: 60px;
  top: calc(50% - 30px);
}

.baguetteBox-button {
  cursor: pointer;
  color: #ddd;
  background-color: #32323280;
  border: 0;
  border-radius: 15%;
  outline: none;
  margin: 0;
  padding: 0;
  font: 1.6em sans-serif;
  transition: background-color .4s;
  position: absolute;
}

.baguetteBox-button:focus, .baguetteBox-button:hover {
  background-color: #323232e6;
}

.baguetteBox-button#next-button {
  right: 2%;
}

.baguetteBox-button#previous-button {
  left: 2%;
}

.baguetteBox-button#close-button {
  width: 30px;
  height: 30px;
  top: 20px;
  right: calc(2% + 6px);
}

.baguetteBox-button svg {
  position: absolute;
  top: 0;
  left: 0;
}

.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.baguetteBox-double-bounce1, .baguetteBox-double-bounce2 {
  opacity: .6;
  background-color: #fff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: 2s ease-in-out infinite bounce;
  position: absolute;
  top: 0;
  left: 0;
}

.baguetteBox-double-bounce2 {
  animation-delay: -1s;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}
/*# sourceMappingURL=index.63cc4582.css.map */
